import { Space } from '@zenlayer/zen-design';
import { Docs } from './docss';
import './index.less';
import UserMenu from './user';

const Header = () => {
  return (
    <div className="header-wrap">
      <Space>
        <Docs />
        <UserMenu />
      </Space>
    </div>
  );
};

export default Header;
