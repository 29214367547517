import { logout } from '@/api/auth';
import { getUser, removeUser } from '@/utils/helper';
import { Avatar, Dropdown, Icon } from '@zenlayer/zen-design';
import { AvatarIcons } from '@zenlayer/zen-design/esm/avatar';
import { useRequest } from 'ahooks';
import './index.less';

const UserMenu = () => {
  const LogoutRequest = useRequest(logout, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        removeUser();
      }
    },
  });
  const user = getUser();
  return (
    <Dropdown
      menu={{
        className: 'header-user-dropdown',
        items: [
          {
            key: 'user',
            className: 'userItem',
            label: (
              <>
                <div className="name">
                  Hi, {user?.firstName} {user?.lastName}
                </div>
                <div className="email">{user?.username}</div>
              </>
            ),
          },
          {
            type: 'divider',
          },
          {
            key: 'logout',
            icon: <Icon type="general/Poweroff" style={{ fontSize: 24 }} />,
            onClick: LogoutRequest.run,
            label: 'Log out',
          },
        ],
      }}
      placement="bottomRight"
    >
      <div className="header-user-menu">
        <Avatar icon={AvatarIcons.zeno} helmet size="m" className="menu-icon" />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
